/* eslint-disable no-underscore-dangle */

import { $path } from 'remix-routes'

export interface RouteInterface {
  name: string
  pathRegex: string | RegExp
  createPath: (params?: any) => string
  meta: {
    title: string
  }
}

export const routeInfos: RouteInterface[] = [
  {
    name: 'login',
    createPath: () => $path('/login'),
    pathRegex: $path('/login'),
    meta: {
      title: 'ログイン',
    },
  },
  {
    name: 'admin-login',
    createPath: () => $path('/admin/login'),
    pathRegex: $path('/admin/login'),
    meta: {
      title: '管理者画面ログイン',
    },
  },
  {
    name: 'admin-users',
    createPath: () => $path('/admin/users'),
    pathRegex: $path('/admin/users'),
    meta: {
      title: 'ユーザー一覧',
    },
  },
  {
    name: 'admin-users-new',
    createPath: () => $path('/admin/users/new'),
    pathRegex: $path('/admin/users/new'),
    meta: {
      title: 'ユーザー作成',
    },
  },
  {
    name: 'admin-users-edit',
    createPath: (params) =>
      $path(`/admin/users/:userId/edit`, { userId: params.userId }),
    pathRegex: /^\/admin\/users\/(\d+)\/edit$/,
    meta: {
      title: 'ユーザー編集',
    },
  },
  {
    name: 'admin-attendance',
    createPath: () => $path('/admin/attendance'),
    pathRegex: $path(`/admin/attendance`),
    meta: {
      title: '勤務状況一覧',
    },
  },
  {
    name: 'admin-projects',
    createPath: () => $path('/admin/projects'),
    pathRegex: $path(`/admin/projects`),
    meta: {
      title: 'プロジェクト・現場一覧',
    },
  },
  {
    name: 'admin-sites-new',
    createPath: (params) =>
      $path('/admin/projects/:projectId/sites/new', {
        projectId: params.projectId,
      }),
    pathRegex: /^\/admin\/projects\/(\d+)\/sites\/new$/,
    meta: {
      title: '現場登録',
    },
  },
  {
    name: 'admin-sites-edit',
    createPath: (params) =>
      $path(`/admin/projects/:projectId/sites/:siteId/edit`, {
        projectId: params.projectId,
        siteId: params.siteId,
      }),
    pathRegex: /^\/admin\/projects\/(\d+)\/sites\/(\d+)\/edit$/,
    meta: {
      title: '現場編集',
    },
  },
]

export const findRoute = (path: string) => {
  const url = new URL(path, 'http://localhost:3000')
  return routeInfos.find((v) => {
    if (typeof v.pathRegex === 'string') {
      return v.pathRegex === url.pathname
    }
    return v.pathRegex.test(path)
  })
}

export const findRouteName = (name: string) => {
  return routeInfos.find((v) => {
    return v.name === name
  })
}
